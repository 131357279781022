import { ref, computed, watch } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import useCryptoJs from '@core/utils/useCryptoJs'
import useSelectOptions from '@core/utils/useSelectOptions'
// eslint-disable-next-line object-curly-newline
import { getFieldsByFacility, getOrderVideos } from '@api'

// import store from '@/store'

export default function useFieldMonitoringList() {
  const { t } = useUtils()
  const { userData } = useCryptoJs()
  const {
    configOrganization,
    configFacility,
  } = useSelectOptions()

  const videoListTable = ref([])
  const fieldOptions = ref([])

  const totalVideoListTable = ref(null)

  const timerFetchVideos = ref(null)

  const fieldFilter = ref(null)
  const dateFilter = ref(
    new Date().toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const now = ref(new Date())
  const nowDate = ref(
    new Date(now.value).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const min = ref(+new Date() - 12096e5)
  const minDate = ref(
    new Date(min.value).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const max = ref(+new Date() + 12096e5)
  const maxDate = ref(
    new Date(max.value).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const loading = ref(false)
  const options = ref({
    sortBy: ['name'],
    sortDesc: [true],
  })
  const userTotalLocal = ref([])
  const selectedRows = ref([])
  const page = ref(1)
  const perPage = ref(100)

  const getVisibleList = () => {
    if (videoListTable.value.length) {
      if (videoListTable.value.length > perPage.value) return videoListTable.value.slice((page.value - 1) * perPage.value, page.value * perPage.value)

      return videoListTable.value
    }

    return []
  }

  const visibleVideoListTable = computed(() => getVisibleList())

  const lengthPagination = computed(() => Math.ceil(totalVideoListTable.value / perPage.value))

  const getGroup = () => {
    if (userData.value && userData.value.role !== 'A') return userData.value.group_id
    if (configOrganization.value) return configOrganization.value

    return 0
  }

  // fetch data
  const fetchVideos = async () => {
    clearTimeout(timerFetchVideos.value)
    timerFetchVideos.value = setTimeout(async () => {
      const orderVideos = await getOrderVideos(
        getGroup(),
        configFacility.value,
        fieldFilter.value || 0,
        dateFilter.value,
        '0',
      )

      videoListTable.value = orderVideos.data.filter(e => e.type === 'O')
      totalVideoListTable.value = orderVideos.data.length
    }, 200)
  }

  const fetchFields = async facilityId => {
    let fields = null
    if (userData.value && userData.value.role !== 'A') {
      // fields = await getFieldsByFacility(userData.value.facility_id)

      if (facilityId) {
        fields = await getFieldsByFacility(facilityId)
      }
    } else if (facilityId) fields = await getFieldsByFacility(facilityId)
    else fields = [] // await getFields()

    if (fields && fields.ok) {
      fieldOptions.value = fields.data
      if (fieldOptions.value.length > 0 && !fieldFilter.value) fieldFilter.value = fieldOptions.value[0].id
    } else fieldOptions.value = []
  }

  watch([dateFilter, fieldFilter], async () => {
    videoListTable.value = []
    totalVideoListTable.value = 0
    await fetchVideos()
  })

  watch([configFacility], async () => {
    fieldOptions.value = []
    fieldFilter.value = 0
    await fetchFields(configFacility.value)
    await fetchVideos()
  })

  watch([configOrganization], async () => {
    await fetchVideos()
  })

  watch([page], () => {
    visibleVideoListTable.value = []
    visibleVideoListTable.value = videoListTable.value.slice(
      (page.value - 1) * perPage.value,
      page.value * perPage.value,
    )
  })

  return {
    userData,
    videoListTable,

    fieldFilter,
    dateFilter,
    now,
    min,
    max,
    nowDate,
    minDate,
    maxDate,
    loading,
    options,
    userTotalLocal,
    selectedRows,
    fieldOptions,
    visibleVideoListTable,
    page,
    perPage,

    configOrganization,
    configFacility,

    t,
    fetchVideos,
    fetchFields,
    lengthPagination,
  }
}
