/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { ref, watch, computed } from '@vue/composition-api'
import { getRecurringOrders, getRecurringOrdersPaged, getFieldsByFacility } from '@api'
import { useUtils } from '@core/libs/i18n'
import { isObjEmpty } from '@core/utils'
import usePermissions from '@core/utils/usePermissions'
import useCryptoJs from '@core/utils/useCryptoJs'
import useSelectOptions from '@core/utils/useSelectOptions'
import useFilters from '@core/utils/useFilters'
import fetchPaged from '@core/utils/fetchPaged'

export default function useRecurringOrderList() {
  const { t } = useUtils()
  const { hasActionPermission, hasPermission } = usePermissions()
  const { configOrganization, configFacility } = useSelectOptions()
  const { userData } = useCryptoJs()
  const { updateFilter, getFilterByModule } = useFilters()

  const listTable = ref([])
  const tableColumns = computed(() => [
    { text: t('organizations.organization').toUpperCase(), value: 'group_name', show: true },
    { text: t('facilities.facility').toUpperCase(), value: 'facility_name', show: true },
    { text: t('fields.field').toUpperCase(), value: 'field_name', show: true },
    { text: t('videos.start_time').toUpperCase(), value: 'start_time', show: true },
    { text: t('videos.minutes').toUpperCase(), value: 'video_time', show: true },
    { text: t('videos.weekdays').toUpperCase(), value: 'weekdays', show: true },
    { text: t('status.status').toUpperCase(), value: 'status', show: true },
    {
      text: t('tooltip.actions').toUpperCase(),
      value: 'actions',
      align: 'center',
      sortable: false,
      show: hasActionPermission(72, 73, 74),
    },
  ])
  const computedTableColumns = computed(() => tableColumns.value.filter(e => e.show))

  const fieldFilter = ref(null)
  const searchQuery = ref('')
  const totalListTable = ref(0)
  const loading = ref(false)
  const filters = ref(null)
  const options = ref({
    sortBy: ['group_name'],
    sortDesc: [true],
  })
  const descSort = ref([])
  const flag = ref(true)
  const hasOptionsFilter = ref(false)
  const timerSearch = ref(null)
  const weekdayOptions = computed(() => [
    { text: t('weekdays.monday'), value: 0 },
    { text: t('weekdays.tuesday'), value: 1 },
    { text: t('weekdays.wednesday'), value: 2 },
    { text: t('weekdays.thursday'), value: 3 },
    { text: t('weekdays.friday'), value: 4 },
    { text: t('weekdays.saturday'), value: 5 },
    { text: t('weekdays.sunday'), value: 6 },
  ])
  const groupsOptions = ref([])
  const facilitiesOptions = ref([])
  const fieldsOptions = ref([])
  const chunksOptions = ref([])

  const fetchFields = async facilityId => {
    let fields = null
    if (userData.value && userData.value.role !== 'A') {
      if (facilityId) {
        fields = await getFieldsByFacility(facilityId)
      }
    } else if (facilityId) fields = await getFieldsByFacility(facilityId)
    else fields = [] // await getFields()

    if (fields && fields.ok) fieldsOptions.value = fields.data
    else fieldsOptions.value = []
  }

  // fetch data
  const fetchRecurringOrders = async () => {
    const response = await getRecurringOrders()
    const filteredData = response.data.filter(
      order =>
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        order.group_name.toLowerCase().includes(searchQuery.value) ||
        order.facility_name.toLowerCase().includes(searchQuery.value) ||
        order.field_name.toLowerCase().includes(searchQuery.value) ||
        order.start_time.toLowerCase().includes(searchQuery.value),
    )

    listTable.value = filteredData
    totalListTable.value = filteredData.length
    loading.value = false
  }

  const fetchPagedRecurringOrders = async () => {
    flag.value = true
    const filterOption = {}
    filters.value = null
    if (searchQuery.value) filterOption.search = searchQuery.value
    if (userData.value && userData.value.role !== 'A') {
      filterOption.group_id = userData.value.group_id
    } else if (configOrganization.value) {
      filterOption.group_id = configOrganization.value
    }
    if (configFacility.value) filterOption.facility_id = configFacility.value
    if (fieldFilter.value) filterOption.field_id = fieldFilter.value

    filters.value = filterOption
    const response = await fetchPaged(getRecurringOrdersPaged, options.value, filterOption)
    listTable.value = response.data
    totalListTable.value = response.total
    loading.value = false
  }

  const setOptions = value => {
    flag.value = false
    if (value) {
      hasOptionsFilter.value = true
      options.value = value
    } else {
      hasOptionsFilter.value = false
    }
  }

  const setFilters = value => {
    flag.value = false
    if (value && !isObjEmpty(value)) {
      if (value.search) searchQuery.value = value.search
      if (value.field_id) fieldFilter.value = value.field_id
    }
  }

  const getDataBySearch = async () => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      await fetchPagedRecurringOrders()
    }, 1000)
  }

  watch([configFacility, configOrganization], async () => {
    fieldFilter.value = null
    searchQuery.value = ''
  })

  watch([configFacility], async () => {
    fieldsOptions.value = []
    fieldFilter.value = 0
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter) options.value.page = 1
    await fetchFields(configFacility.value)
    await fetchPagedRecurringOrders()
  })

  watch([configOrganization], async () => {
    facilitiesOptions.value = []
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter) options.value.page = 1
    await fetchPagedRecurringOrders()
  })

  watch([fieldFilter], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter) options.value.page = 1
    await fetchPagedRecurringOrders()
  })

  watch([searchQuery], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter) options.value.page = 1
    updateFilter('views-recurring-order-list', 'search', searchQuery.value)
    await fetchPagedRecurringOrders()
  })

  watch([options], async () => {
    if (flag.value) {
      loading.value = true
      await fetchPagedRecurringOrders()
    }
  })

  // watch([searchQuery, options], () => {
  //   loading.value = true
  //   fetchRecurringOrders()
  // })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const resolveSportStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active' || status === 'A') return 'success'
    if (status === 'inactive' || status === 'I') return 'error'

    return 'primary'
  }

  const resolveSportStatusText = status => {
    if (status === 'A') return 'Activo'
    if (status === 'I') return 'Inactivo'

    return 'Administrator'
  }

  const compareDays = day => {
    if (day === 0) return t('weekdays.monday')
    if (day === 1) return t('weekdays.tuesday')
    if (day === 2) return t('weekdays.wednesday')
    if (day === 3) return t('weekdays.thursday')
    if (day === 4) return t('weekdays.friday')
    if (day === 5) return t('weekdays.saturday')

    return t('weekdays.sunday')
  }

  const resolveWeekdaysText = weekdays => {
    if (weekdays && weekdays.length) {
      return weekdays.map(e => compareDays(e)).join(', ')
    }

    return ''
  }

  return {
    listTable,
    tableColumns,
    computedTableColumns,
    fieldFilter,
    searchQuery,
    totalListTable,
    loading,
    filters,
    options,
    descSort,
    weekdayOptions,
    userData,
    groupsOptions,
    facilitiesOptions,
    fieldsOptions,
    chunksOptions,
    configFacility,

    fetchRecurringOrders,
    resolveSportStatusVariant,
    resolveSportStatusText,
    resolveWeekdaysText,
    hasPermission,
    fetchFields,
    fetchPagedRecurringOrders,
    setOptions,
    setFilters,
    getDataBySearch,
    updateFilter,
    getFilterByModule,
  }
}
