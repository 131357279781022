<template>
  <canvas
    ref="refQr"
    :class="computedShow ? 'show': 'qrcode-canvas'"
    :style="{height: computedSize + 'px', width: computedSize + 'px'}"
    :height="computedSize"
    :width="computedSize"
  ></canvas>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import { ref, computed, watch, onMounted, getCurrentInstance } from '@vue/composition-api'

// import qr from 'qr.js'
import QRCode from 'qrcode'

export default {
  name: 'Qr',
  props: {
    value: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 200,
    },

    // 'L', 'M', 'Q', 'H'
    level: {
      type: String,
      default: 'Q',
    },
    bgColor: {
      type: String,
      default: '#FFFFFF',
    },
    fgColor: {
      type: String,
      default: '#000000',
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const refQr = ref(null)
    const vm = getCurrentInstance().proxy
    const computedValue = computed(() => props.value)
    const computedSize = computed(() => props.size)
    const computedLevel = computed(() => props.level)
    const computedBgColor = computed(() => props.bgColor)
    const computedFgColor = computed(() => props.fgColor)
    const computedShow = computed(() => props.show)

    const getBackingStorePixelRatio = ctx =>
      ctx.webkitBackingStorePixelRatio ||
      ctx.mozBackingStorePixelRatio ||
      ctx.msBackingStorePixelRatio ||
      ctx.oBackingStorePixelRatio ||
      ctx.backingStorePixelRatio ||
      1

    const update = () => {
      const $qr = vm.$refs.refQr
      const qrcode = QRCode.toCanvas($qr, computedValue.value, error => {
        console.error('error', error, qrcode)
      })

      // const ctx = $qr.getContext('2d')
      // const cells = qrcode.modules
      // const tileW = computedSize.value / cells.length
      // const tileH = computedSize.value / cells.length
      // const scale = (window.devicePixelRatio || 1) / getBackingStorePixelRatio(ctx)

      // $qr.height = computedSize.value * scale
      // $qr.width = computedSize.value * scale

      // ctx.scale(scale, scale)

      // cells.forEach((row, rdx) => {
      //   row.forEach((cell, cdx) => {
      //     ctx.fillStyle = cell ? computedFgColor.value : computedBgColor.value
      //     const w = Math.ceil((cdx + 1) * tileW) - Math.floor(cdx * tileW)
      //     const h = Math.ceil((rdx + 1) * tileH) - Math.floor(rdx * tileH)
      //     ctx.fillRect(Math.round(cdx * tileW), Math.round(rdx * tileH), w, h)
      //   })
      // })
    }

    const isShow = () => {
      emit('isShow', props.show)
    }

    watch([computedValue, computedSize, computedLevel, computedBgColor, computedFgColor, computedShow], () => {
      update()
    })

    onMounted(() => {
      update()
    })

    return {
      // data
      refQr,

      // computed
      computedValue,
      computedSize,
      computedLevel,
      computedBgColor,
      computedFgColor,
      computedShow,

      // methods
      update,
      getBackingStorePixelRatio,
      isShow,
    }
  },
}
</script>

<style lang="sass">
.qrcode-canvas
  display: none

  .show
    display: block !important
</style>
