/* eslint-disable operator-linebreak */
import { ref, watch, computed } from '@vue/composition-api'
import { getHighlightsPaged } from '@api'
import { useUtils } from '@core/libs/i18n'
import fetchPaged from '@core/utils/fetchPaged'
import usePermissions from '@core/utils/usePermissions'
import useCryptoJs from '@core/utils/useCryptoJs'
import { isObjEmpty } from '@core/utils'
import useSelectOptions from '@/@core/utils/useSelectOptions'
import useFilters from '@core/utils/useFilters'

export default function useHighlightsList() {
  const listTable = ref([])
  const { hasActionPermission, hasPermission } = usePermissions()
  const { t } = useUtils()
  const { updateFilter, getFilterByModule } = useFilters()
  const { userData } = useCryptoJs()
  const {
    configOrganization,
    configFacility,
  } = useSelectOptions()

  const tableColumns = computed(() => [
    { text: `${t('highlights.highlight').toUpperCase()} ID`, value: 'id', show: true },
    { text: t('facilities.facility').toUpperCase(), value: 'facility_name', show: true },
    { text: t('highlights.highlight_name').toUpperCase(), value: 'name', show: true },
    { text: `${t('videos.video').toUpperCase()} ID`, value: 'video_id', show: true },
    { text: t('videos.date').toUpperCase(), value: 'video_date', show: true },
    { text: t('users.username').toUpperCase(), value: 'username', show: true },
    {
      text: t('tooltip.actions').toUpperCase(),
      value: 'actions',
      align: 'center',
      sortable: false,
      show: hasActionPermission(79, 80),
    },
  ])
  const computedTableColumns = computed(() => tableColumns.value.filter(e => e.show))
  const totalListTable = ref(0)
  const loading = ref(false)
  const filters = ref(null)
  const descSort = ref([])
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })

  const flag = ref(true)
  const timerSearch = ref(null)
  const hasOptionsFilter = ref(true)

  const searchQuery = ref('')
  const typeFilter = ref(null)
  const typesOptions = computed(() => [
    { text: t('highlights.highlight'), value: 'H' },
    { text: t('highlights.goal'), value: 'G' },
    { text: t('highlights.foul'), value: 'F' },
    { text: t('highlights.assist'), value: 'A' },
  ])

  const getGroup = () => {
    if (userData.value && userData.value.role !== 'A') return userData.value.group_id
    if (configOrganization.value) return configOrganization.value

    return 0
  }

  const fetchPagedHighlights = async () => {
    flag.value = true
    const filterOption = {}
    filters.value = null
    filterOption.group_id = getGroup()
    if (searchQuery.value) filterOption.search = searchQuery.value
    if (configFacility.value) filterOption.facility_id = configFacility.value
    if (typeFilter.value) filterOption.type = typeFilter.value
    filters.value = filterOption

    const response = await fetchPaged(getHighlightsPaged, options.value, filterOption)
    listTable.value = response.data
    totalListTable.value = response.total
    loading.value = false
  }

  const setOptions = value => {
    flag.value = false
    if (value) {
      hasOptionsFilter.value = true
      options.value = value
    } else {
      hasOptionsFilter.value = false
    }
  }

  const setFilters = value => {
    flag.value = false
    if (value && !isObjEmpty(value)) {
      if (value.search) searchQuery.value = value.search
      if (value.type) typeFilter.value = value.type
    }
  }

  const getDataBySearch = async (time = 1000) => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      await fetchPagedHighlights()
    }, time)
  }

  watch([configFacility, configOrganization], async () => {
    searchQuery.value = ''
    typeFilter.value = null
  })

  watch([configFacility, typeFilter], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter) options.value.page = 1
    await getDataBySearch(200)
  })

  watch([configOrganization], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter) options.value.page = 1
    await getDataBySearch(500)
  })

  watch([searchQuery], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter) options.value.page = 1
    updateFilter('views-highlight-list', 'search', searchQuery.value)
    await getDataBySearch()
  })

  watch([options], async () => {
    if (flag.value) {
      loading.value = true
      await fetchPagedHighlights()
    }
  })

  const resolveClientAvatarVariant = status => {
    if (status === 'Partial Video') return 'primary'
    if (status === 'R' || status === 'C' || status === 'I') return 'error'
    if (status === 'Downloaded') return 'secondary'
    if (status === 'P') return 'warning'
    if (status === 'S') return 'info'
    if (status === 'A') return 'success'

    return 'secondary'
  }

  return {
    listTable,
    computedTableColumns,
    totalListTable,
    loading,
    filters,
    options,
    descSort,

    searchQuery,
    typeFilter,
    typesOptions,

    configOrganization,
    configFacility,

    t,
    resolveClientAvatarVariant,
    hasPermission,
    setFilters,
    setOptions,
    fetchPagedHighlights,
    updateFilter,
    getFilterByModule,
    getDataBySearch,
  }
}
