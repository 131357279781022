<template>
  <div
    id="field-monitoring-list"
  >
    <v-card
      :color="lengthPagination && !isDark ? 'transparent' : ''"
      :class="{'elevation-0': lengthPagination, 'm-card': !lengthPagination }"
    >
      <!-- <v-card-title>
        {{ computedDateFormat }}
      </v-card-title> -->

      <v-card-title>
        {{ t('tooltip.filters') }} {{ computedDateFormat }}
      </v-card-title>

      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          md="2"
          sm="3"
        >
          <v-select
            v-model="fieldFilter"
            :label="t('fields.field')"
            :placeholder="t('fields.field')"
            :items="fieldOptions"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            @change="updateFilter('views-field-monitoring-list', 'field', fieldFilter)"
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="2"
        >
          <v-menu
            v-model="modal"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFilter"
                :label="t('videos.date')"
                :placeholder="t('videos.date')"
                :prepend-icon="icons.mdiCalendar"
                outlined
                readonly
                dense
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateFilter"
              :max="maxDate"
              :min="minDate"
              color="primary"
              @input="modal = false"
              @change="updateFilter('views-field-monitoring-list', 'date', dateFilter)"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-btn-toggle
            v-model="toggleDay"
            mandatory
            color="secondary"
            class="elevation-0"
          >
            <v-btn
              class="elevation-0"
              @click="onToggleDay(0)"
            >
              {{ t('tooltip.today') }}
            </v-btn>

            <v-btn
              class="elevation-0"
              @click="onToggleDay(1)"
            >
              {{ t('tooltip.prev') }}
            </v-btn>

            <v-btn
              class="elevation-0"
              @click="onToggleDay(2)"
            >
              {{ t('tooltip.next') }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>

      <v-divider />

      <v-row class="mx-0 mt-4">
        <v-col
          v-for="(video, i) in visibleVideoListTable"
          :key="i"
          md="3"
          sm="6"
          cols="12"
          class="align-self-start p-card"
        >
          <v-card
            :color="$vuetify.theme.dark ? '#1d1d1d' : '#fff'"
            class="rounded-lg"
            elevation="15"
            height="310"
          >
            <v-card-text class="pa-3">
              <div>
                <div
                  class="ms-0 width-full"
                >
                  <v-row class="pa-1">
                    <v-col
                      cols="12"
                      class="pa-1 d-flex justify-space-between align-center"
                    >
                      <div class="d-flex">
                        <v-avatar
                          color="primary"
                          :class="video.facility_logo ? '' : 'v-avatar-light-bg primary--text'"
                          size="32"
                        >
                          <v-img
                            v-if="video.facility_logo"
                            :src="video.facility_logo"
                          ></v-img>
                          <span
                            v-else
                            class="font-weight-medium"
                          >{{ avatarText(video.field_name) }}</span>
                        </v-avatar>
                        <div class="d-flex flex-column ml-2">
                          <span class="text-xs">{{ video.video_date_str }}</span>
                          <span class="text-xs">{{ video.field_name }}</span>
                        </div>
                      </div>
                      <span class="text-xs">{{ video.day_time }}</span>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-card-text>

            <v-carousel
              height="205px"
              width="auto"
              hide-delimiters
              show-arrows-on-hover
              cycle
              :show-arrows="false"
            >
              <v-carousel-item
                v-for="(item, index) in video.thumbnails"
                :key="index"
                :src="item"
                eager
                class="pointer d-flex justify-center align-center"
                @click="onOpen(video)"
              >
                <span
                  name="play"
                  class="rounded-icon play-icon"
                >
                  <v-icon
                    color="white"
                    size="18"
                  >
                    {{ icons.mdiPlay }}
                  </v-icon>
                </span>
              </v-carousel-item>
            </v-carousel>

            <v-card-actions class="dense mt-1 px-2">
              <v-row
                style="display: contents;"
              >
                <v-col
                  cols="12"
                  class="d-flex justify-space-between px-0 align-center"
                >
                  <div>
                    <v-btn
                      v-if="hasPermission(85)"
                      class="ma-0 mr-2"
                      x-small
                      depressed
                      style="min-width: 40px; padding: 0px 5px; font-size:10px;"
                      outlined
                      color="secondary"
                      @click="onOpen(video)"
                    >
                      {{ t('tooltip.view_detail') }}
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          class="text-center align-self-center pa-4"
        >
          <v-pagination
            v-if="lengthPagination"
            v-model="page"
            :length="lengthPagination"
            :total-visible="7"
          ></v-pagination>
          <p
            v-if="!lengthPagination"
            class="mt-3"
          >
            {{ t('tooltip.no_data') }}
          </p>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog
      v-model="isDialogVisible"
      width="600px"
    >
      <v-card rounded="10">
        <v-app-bar
          flat
          color="rgba(0, 0, 0, 0)"
          dense
          class="px-4"
        >
          <v-spacer />
          <v-toolbar-title class="text-h6 pl-0 text-center">
            {{ videoOpened.video_date_str }}
          </v-toolbar-title>
          <v-spacer />
          <v-icon
            @click="isDialogVisible = false"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </v-app-bar>

        <v-card-text
          v-if="activityControl.length"
          class="pa-3"
        >
          <v-row
            v-for="(item, i) in activityControl"
            :key="i"
            align="center"
            justify="center"
            style="border-top: 0.6px solid; border-bottom: 0.6px solid;"
          >
            <v-col
              cols="12"
              md="2"
              class="d-flex justify-center align-center"
            >
              {{ item.start_time }}
            </v-col>

            <v-col
              cols="12"
              md="4"
              class="d-flex justify-center align-center"
            >
              <v-img
                :src="item.left_video_thumbnail"
                :lazy-src="item.left_video_thumbnail"
                :class="{'pointer': item.left_video_url}"
                @click="() => {
                  if (hasPermission(86) && item.left_video_url) return onShowVideo(item, 'left')

                  return null
                }"
              >
                <v-row
                  v-if="hasPermission(86)"
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <span
                    name="left"
                    class="rounded-icon-sm"
                  >
                    <v-icon
                      v-if="item.left_video_url"
                      color="white"
                      size="12"
                      @click="onShowVideo(item, 'left')"
                    >
                      {{ icons.mdiPlay }}
                    </v-icon>
                    <v-icon
                      v-else
                      color="white"
                      size="12"
                    >
                      {{ icons.mdiBlockHelper }}
                    </v-icon>
                  </span>
                </v-row>
              </v-img>
            </v-col>

            <v-col
              v-if="(item.left_video_thumbnail !== item.right_video_thumbnail) ||
                (item.left_video_thumbnail === item.right_video_thumbnail && item.left_video_thumbnail.includes('no-thumb'))"
              cols="12"
              md="4"
              class="d-flex justify-center align-center"
            >
              <v-img
                :src="item.right_video_thumbnail"
                :lazy-src="item.right_video_thumbnail"
                :class="{'pointer': item.left_video_url}"
                @click="() => {
                  if (hasPermission(86) && item.right_video_url) return onShowVideo(item, 'right')

                  return null
                }"
              >
                <v-row
                  v-if="hasPermission(86)"
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <span
                    name="right"
                    class="rounded-icon-sm"
                  >
                    <v-icon
                      v-if="item.right_video_url"
                      color="white"
                      size="12"
                      @click="onShowVideo(item, 'right')"
                    >
                      {{ icons.mdiPlay }}
                    </v-icon>
                    <v-icon
                      v-else
                      color="white"
                      size="12"
                    >
                      {{ icons.mdiBlockHelper }}
                    </v-icon>
                  </span>
                </v-row>
              </v-img>
            </v-col>

            <v-col
              cols="12"
              md="2"
            ></v-col>
          </v-row>
        </v-card-text>

        <v-card-text
          v-else
          class="pa-3"
        >
          <v-row
            align="center"
            justify="center"
            style="border-top: 0.6px solid; border-bottom: 0.6px solid;"
          >
            <v-col
              cols="12"
              class="text-center align-self-center pa-4 pt-2"
            >
              <p
                class="mt-3"
              >
                {{ t('tooltip.no_data') }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isDialogVideoVisible"
      max-width="90%"
    >
      <video-player
        v-if="isDialogVideoVisible"
        :data-params="videoData"
        :option="2"
        :direction="videoDirection"
        @close="() => isDialogVideoVisible = false"
      />
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
import { mdiPlay, mdiBlockHelper, mdiClose } from '@mdi/js'
import { onMounted, ref, computed } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { getChunksActivityControl } from '@api'
import useAppConfig from '@core/@app-config/useAppConfig'
import usePermissions from '@core/utils/usePermissions'
import useFilters from '@core/utils/useFilters'
import moment from 'moment'

import useFieldMonitoringList from './useFieldMonitoringList'

import VideoPlayer from './VideoPlayer.vue'

export default {
  components: {
    VideoPlayer,
  },
  setup() {
    const { t } = useUtils()
    const { hasPermission } = usePermissions()
    const { isDark } = useAppConfig()
    const { updateFilter, getFilterByModule } = useFilters()
    const {
      dateFilter,
      fieldFilter,
      fieldOptions,

      now,
      min,
      max,
      nowDate,
      maxDate,
      minDate,

      visibleVideoListTable,
      page,
      configFacility,

      fetchVideos,
      fetchFields,
      lengthPagination,
    } = useFieldMonitoringList()

    const toggleDay = ref(null)
    const isDialogVisible = ref(false)
    const isDialogVideoVisible = ref(false)
    const activityControl = ref([])
    const videoOpened = ref({
      video_date_str: '',
    })
    const videoData = ref(null)
    const videoDirection = ref('left')
    const modal = ref(null)

    const computedDateFormat = computed(() => moment(dateFilter.value).format('ll'))

    const onOpen = async item => {
      const resp = await getChunksActivityControl(item.field_id, item.video_date, item.start_time, 15)
      if (resp.ok) {
        videoOpened.value = item
        activityControl.value = resp.data
        isDialogVisible.value = true
      }
    }

    const onShowVideo = async (item, direction) => {
      videoDirection.value = direction
      videoData.value = {
        ...item,
        ...videoOpened.value,
      }
      isDialogVideoVisible.value = true
    }

    const onToggleDay = e => {
      const result = new Date(dateFilter.value)
      if (e === 1) {
        if (result > min.value) {
          result.setDate(result.getDate() - 1)
          dateFilter.value = result.toISOString().substring(0, 10)
        }
      } else if (e === 2) {
        const mapMax = new Date(maxDate.value)
        if (result < mapMax) {
          result.setDate(result.getDate() + 1)
          dateFilter.value = result.toISOString().substring(0, 10)
        }
      } else {
        dateFilter.value = nowDate.value
      }
    }

    onMounted(async () => {
      await fetchFields(configFacility.value)

      const filtersStored = getFilterByModule('views-field-monitoring-list')
      if (filtersStored.field) fieldFilter.value = filtersStored.field
      if (filtersStored.date) dateFilter.value = filtersStored.date

      await fetchVideos()
    })

    return {
      dateFilter,
      fieldFilter,
      fieldOptions,
      isDark,

      now,
      min,
      max,
      nowDate,
      maxDate,
      minDate,

      isDialogVisible,
      isDialogVideoVisible,
      activityControl,
      videoOpened,
      videoData,
      videoDirection,

      visibleVideoListTable,
      toggleDay,
      page,
      modal,

      computedDateFormat,

      fetchVideos,
      fetchFields,
      onToggleDay,
      lengthPagination,
      onOpen,
      onShowVideo,
      hasPermission,
      updateFilter,

      // i18n
      t,

      // icons
      icons: {
        mdiPlay,
        mdiBlockHelper,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/apps/user.scss';

.v-responsive__content {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.v-image__image {
  background-size: 100% 100%;
}

div.v-slide-group__prev,
div.v-slide-group__next {
  min-width: 30px;

  svg.v-icon__svg {
    height: 20px;
    width: 20px;
    fill: currentColor;
  }
}

.v-input.detail-select.v-input__control.v-input__slot {
  min-height: 30px;
  height: 30px;
}

.detail-select.v-input__control.v-input__slot,
.detail-select.v-list-item__action .v-input__control {
  min-height: 30px;
  height: 30px;
}

.v-window__prev,
.v-window__next {
  button {
    font-size: 20px !important;
  }

  v-icon {
    font-size: 20px;
  }

  .lock-icon {
    position: absolute !important;
    top: 35% !important;
    right: 50% !important;
    transform: translate(50%, 50%) !important;
  }

  div .v-input__append-outer {
    margin: 0;
  }

  .width-full {
    width: -webkit-fill-available;
    width: fit-content;
  }
}

.absolute-chip {
  bottom: 30px;
  right: 10px;
  position: absolute;
}

.p-card {
  padding: 10px !important;
  padding-top: 0 !important;
}

.m-card {
  margin-inline: 10px !important;
}

.mt-filter-row {
  margin-top: 0px !important;
}

.rounded-icon {
  background-color: #00000099;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rounded-icon-sm {
  background-color: #00000099;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pointer {
  cursor: pointer;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .p-card {
    padding: 12px !important;
    padding-top: 0 !important;
  }

  .m-card {
    margin-inline: 12px !important;
  }

  .absolute-chip {
    right: 20px;
    bottom: 30px;
  }

  .mt-filter-row {
    margin-top: 24px !important;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .p-card {
    padding: 12px !important;
    padding-top: 0 !important;
  }

  .m-card {
    margin-inline: 12px !important;
  }

  .absolute-chip {
    right: 20px;
    bottom: 30px;
  }

  .mt-filter-row {
    margin-top: 24px !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .p-card {
    padding: 12px !important;
    padding-top: 0 !important;
  }

  .m-card {
    margin-inline: 12px !important;
  }

  .absolute-chip {
    right: 20px;
    bottom: 50px;
  }

  .mt-filter-row {
    margin-top: 0px !important;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .p-card {
    padding: 10px !important;
    padding-top: 0 !important;
    bottom: 30px;
  }

  .m-card {
    margin-inline: 10px !important;
  }

  .absolute-chip {
    right: 10px;
    bottom: 40px;
  }

  .mt-filter-row {
    margin-top: 0px !important;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .p-card {
    padding: 10px !important;
    padding-top: 0 !important;
  }

  .m-card {
    margin-inline: 10px !important;
  }

  .absolute-chip {
    right: 10px;
    bottom: 40px;
  }

  .mt-filter-row {
    margin-top: 0px !important;
  }
}
</style>
