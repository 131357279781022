<template>
  <v-dialog
    v-model="isModalOpen"
    max-width="420px"
    persistent
  >
    <v-card
      class="pa-3"
      rounded="10"
      style="width: 100%"
    >
      <v-form ref="form">
        <!-- <div>
          <v-app-bar
            flat
            color="rgba(0, 0, 0, 0)"
            dense
            class="px-4"
          >
            <v-toolbar-title class="text-h6 white--text pl-0">
              {{ t('notify_players.game_organizer') }}
            </v-toolbar-title>
          </v-app-bar>

          <v-card-text class="pa-0 mt-5 mb-7 justify-center d-flex flex-column">
            <v-row class="justify-center align-center">
              <v-col
                cols="12"
                md="10"
                class="py-0"
              >
                <div class="d-flex justify-space-between align-items-start">
                  <v-select
                    v-model="phoneInputOrganizer.code"
                    dense
                    outlined
                    :items="phoneCodeOptions"
                    item-text="text"
                    item-value="value"
                    style="
                      width: 35%;
                    "
                    :append-icon="icons.mdiMenuDown"
                    hide-details
                    :rules="[required]"
                    @change="() => phoneInputOrganizer.value = null"
                  >
                    <template v-slot:item="{ item }">
                      <img
                        :src="item.image"
                        :alt="item.value"
                        width="20"
                        height="auto"
                        class="mr-2"
                      >
                      {{ item.text }}
                    </template>
                  </v-select>
                  <v-text-field
                    v-model="phoneInputOrganizer.value"
                    :label="t('notify_players.organizer_phone')"
                    dense
                    outlined
                    class="pl-2"
                    :disabled="!phoneInputOrganizer.code"
                    :rules="computedRequiredOrganizerPhone ? [required, validatePhones(phoneInputOrganizer.value, phoneInputOrganizer)] : [validatePhones(phoneInputOrganizer.value, phoneInputOrganizer)]"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </div> -->

        <div>
          <v-app-bar
            flat
            color="rgba(0, 0, 0, 0)"
            dense
            class="px-4"
          >
            <v-toolbar-title
              class="text-h6  pl-0"
              :class="{'white--text': $vuetify.theme.isDark, 'black--text': !$vuetify.theme.isDark}"
            >
              {{ t('notify_players.notify_players') }}
            </v-toolbar-title>
          </v-app-bar>

          <v-card-text class="pa-0 mt-5 mb-7 justify-center d-flex flex-column">
            <v-row class="justify-center align-center">
              <v-col
                v-for="(phoneInput, index) in phoneInputs"
                :key="index"
                cols="12"
                md="10"
                class="py-0"
              >
                <div class="d-flex justify-space-between align-items-start">
                  <v-select
                    v-model="phoneInput.code"
                    dense
                    outlined
                    :items="phoneCodeOptions"
                    item-text="text"
                    item-value="value"
                    style="
                      width: 35%;
                    "
                    :append-icon="icons.mdiMenuDown"
                    hide-details
                    :rules="requiredPhone && index === 0 ? [required] : []"
                    @change="() => phoneInput.value = null"
                  >
                    <template v-slot:item="{ item }">
                      <img
                        :src="item.image"
                        :alt="item.value"
                        width="20"
                        height="auto"
                        class="mr-2"
                      >
                      {{ item.text }}
                    </template>
                  </v-select>

                  <!-- :rules="phoneInput.code ? [validatePhones(phoneInput.value, phoneInput)] : []" -->
                  <v-text-field
                    v-model="phoneInput.value"
                    :label="t('notify_players.phone')"
                    dense
                    outlined
                    class="pl-2"
                    :class="{ 'mb-2': index > 0 }"
                    :readonly="!phoneInput.code"
                    :hide-details="index > 0"
                    :rules="(computedRequiredOrganizerPhone || phoneInput.code) ? [required, validatePhones(phoneInput.value, phoneInput)] : [validatePhones(phoneInput.value, phoneInput)]"
                    :append-icon="index > 0 ? icons.mdiClose : null"
                    @click:append="deletePhoneNumber(index)"
                    @keydown.enter.prevent="addPhoneNumber"
                  ></v-text-field>
                </div>
              </v-col>

              <v-col
                cols="12"
                md="10"
                class="py-0"
              >
                <p
                  id="addBtn"
                  class="text-body-1 ma-0 btn-not-hov"
                  style="max-width: 140px;"
                >
                  + {{ t('notify_players.add_phone') }}
                </p>

                <!-- <v-btn
                  class="text-body-1 text-capitalize pa-0 btn-not-hov"
                  color="secondary"
                  text
                  rounded
                  plain
                  style="height: 25px !important"
                  @click="addPhoneNumber"
                >
                  + {{ t('notify_players.add_phone') }}
                </v-btn> -->
              </v-col>
            </v-row>
          </v-card-text>
        </div>

        <div v-if="hasPermission(75)">
          <v-app-bar
            flat
            color="rgba(0, 0, 0, 0)"
            dense
            class="mt-3 px-4"
          >
            <v-toolbar-title
              class="text-h6 pl-0"
              :class="{'white--text': $vuetify.theme.isDark, 'black--text': !$vuetify.theme.isDark}"
            >
              {{ t('Recurring Orders') }}
            </v-toolbar-title>
          </v-app-bar>

          <v-card-text class="pa-0 my-5 justify-center d-flex flex-column">
            <v-row class="justify-center align-center">
              <v-col
                cols="12"
                md="10"
                class="py-0"
              >
                <v-checkbox
                  v-model="notifyData.make_recurring"
                  class="ma-auto mb-3 pa-0 text-body-1"
                  :label="t('videos.make_recurring')"
                  color="secondary"
                  dense
                  hide-details
                  @change="onMakeRecurring"
                ></v-checkbox>

                <v-select
                  v-model="notifyData.weekdays"
                  :label="t('videos.weekdays')"
                  :items="weekdayOptions"
                  item-text="text"
                  item-value="value"
                  :menu-props="{ maxHeight: '400' }"
                  multiple
                  persistent-hint
                  dense
                  outlined
                  class="mb-0"
                  :readonly="!notifyData.make_recurring"
                  :disabled="!notifyData.make_recurring"
                  :rules="notifyData.make_recurring ? [required] : []"
                >
                  <template #selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      label
                      dense
                      :input-value="data.selected"
                      @click="data.select"
                    >
                      {{ data.item.text }}
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </div>

        <v-card-actions class="d-flex flex-column justify-center py-3">
          <p class="text-xs text-center mb-3">
            {{ t('notify_players.message') }}
          </p>
          <div class="d-flex justify-space-between">
            <v-btn
              class="text-xs"
              color="secondary"
              dark
              width="55%"
              rounded
              @click="onSave"
            >
              {{ t('tooltip.confirm_order') }}
            </v-btn>
            <v-btn
              class="text-xs"
              color="lightGray"
              dark
              width="30%"
              rounded
              outlined
              @click="$emit('update:is-modal-open', false)"
            >
              {{ t('tooltip.cancel') }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable arrow-body-style */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import { ref, computed, watch, onBeforeMount, onMounted } from '@vue/composition-api'
import { mdiPlus, mdiDeleteOutline, mdiClose, mdiMenuDown } from '@mdi/js'
import { required } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { error } from '@core/utils/toasted'
import usePhone from '@core/utils/usePhone'
import usePermissions from '@core/utils/usePermissions'
import useCountry from '@core/utils/useCountry'

import Vue from 'vue'
import { VueMaskDirective } from 'v-mask'

export default {
  name: 'NofityPlayers',
  model: {
    prop: 'isModalOpen',
    event: 'update:is-modal-open',
  },
  props: {
    isModalOpen: {
      type: Boolean,
      required: true,
    },
    requiredPhone: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredOrganizerPhone: {
      type: Boolean,
      required: false,
      default: false,
    },
    facilityPhoneCode: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { hasPermission } = usePermissions()
    const { getCountryCode } = useCountry()
    const { phoneCodeOptions, validatePhones } = usePhone()
    const form = ref(null)

    // organizer_phone_number: null,
    const notifyData = ref({
      phone: null,
      phone_numbers: [],
      make_recurring: false,
      weekdays: [],
    })
    const initCode = ref('')
    const phoneInputs = ref([
      { value: null, code: initCode.value, phone: null },
    ])

    // const phoneInputOrganizer = ref({ value: null, code: null, phone: null })
    const computedIsModalOpen = computed(() => props.isModalOpen)
    const computedRequiredPhone = computed(() => {
      if (props.requiredPhone) return true

      return notifyData.value.make_recurring
    })
    const computedFacilityPhoneCode = computed(() => props.facilityPhoneCode)
    const computedRequiredOrganizerPhone = computed(() => props.requiredOrganizerPhone)
    const weekdayOptions = computed(() => [
      { text: t('weekdays.monday'), value: 0 },
      { text: t('weekdays.tuesday'), value: 1 },
      { text: t('weekdays.wednesday'), value: 2 },
      { text: t('weekdays.thursday'), value: 3 },
      { text: t('weekdays.friday'), value: 4 },
      { text: t('weekdays.saturday'), value: 5 },
      { text: t('weekdays.sunday'), value: 6 },
    ])

    const addPhoneNumber = () => {
      if (phoneInputs.value.length < 30) {
        phoneInputs.value.push({ value: null, code: initCode.value, phone: null })
      }
    }

    const deletePhoneNumber = index => {
      phoneInputs.value.splice(index, 1)
    }

    const onMakeRecurring = e => {
      if (!e) notifyData.value.weekdays = []
    }

    const onSave = () => {
      // notifyData.value.organizer_phone_number = phoneInputOrganizer.value.phone
      notifyData.value.phone_numbers = phoneInputs.value.filter(e => e.phone).map(e => e.phone)

      if (!computedRequiredOrganizerPhone.value && !notifyData.value.make_recurring) {
        emit('continue', notifyData.value)
      } else {
        if ((computedRequiredOrganizerPhone.value || notifyData.value.make_recurring) && !notifyData.value.phone_numbers.length) {
          form.value.validate()
          error('You must add at least one phone number!')

          return false
        }

        if (form.value.validate()) emit('continue', notifyData.value)
      }
    }

    const initPhone = () => {
      let codeFinded = null
      if (computedFacilityPhoneCode.value) {
        codeFinded = phoneCodeOptions.value.find(e => e.value === computedFacilityPhoneCode.value)
        if (codeFinded) initCode.value = computedFacilityPhoneCode.value
      }

      if (!computedFacilityPhoneCode.value || !codeFinded) {
        const countryCode = getCountryCode()
        if (countryCode) {
          codeFinded = phoneCodeOptions.value.find(e => e.value === countryCode)
          if (codeFinded) initCode.value = countryCode
          else initCode.value = 'US'
        }
      }

      // phoneInputOrganizer.value.code = initCode.value
      phoneInputs.value[0].code = initCode.value
    }

    watch([computedIsModalOpen], () => {
      if (computedIsModalOpen.value) {
        setTimeout(() => {
          const btn = document.getElementById('addBtn')
          btn.addEventListener('click', addPhoneNumber)

          initPhone()
        }, 200)
      } else {
        form.value.reset()
      }
    })

    onBeforeMount(() => {
      Vue.directive('mask', VueMaskDirective)
    })

    onMounted(() => {
      initPhone()
    })

    return {
      // data
      // phoneInputOrganizer,
      form,
      notifyData,
      phoneInputs,

      // computed
      computedRequiredPhone,
      computedRequiredOrganizerPhone,
      computedIsModalOpen,
      weekdayOptions,
      phoneCodeOptions,
      computedFacilityPhoneCode,

      // methods
      addPhoneNumber,
      deletePhoneNumber,
      onSave,
      required,
      hasPermission,
      onMakeRecurring,
      validatePhones,

      // i18n
      t,

      // icons
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiClose,
        mdiMenuDown,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
  .phone-item {
    min-height: 25px;
    height: 25px;
  }

  .btn-not-hov {
    color: #ff3232 !important;
    caret-color: #ff3232 !important;
    cursor: pointer;
  }
</style>
