<template>
  <div
    id="order-video-list"
    :key="videoComponentKey"
  >
    <v-card
      :color="lengthPagination && !isDark ? 'transparent' : ''"
      class="mb-8"
      :class="{'elevation-0': lengthPagination }"
    >
      <v-card-title>
        {{ t('tooltip.filters') }}
      </v-card-title>

      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          md="2"
          sm="3"
        >
          <v-select
            v-model="fieldFilter"
            :label="t('fields.field')"
            :placeholder="t('fields.field')"
            :items="fieldOptions"
            item-text="name"
            item-value="id"
            outlined
            clearable
            dense
            hide-details
            @change="updateFilter('views-order-video-list', 'field', fieldFilter)"
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="2"
        >
          <v-menu
            v-model="modal"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFilter"
                :label="t('videos.date')"
                :placeholder="t('videos.date')"
                :prepend-icon="icons.mdiCalendar"
                outlined
                readonly
                dense
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <!-- :max="maxDate"
            :min="minDate" -->
            <v-date-picker
              v-model="dateFilter"
              color="secondary"
              @input="modal = false"
              @change="updateFilter('views-order-video-list', 'date', dateFilter)"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          sm="2"
        >
          <v-select
            v-model="chunkFilter"
            :label="t('videos.time')"
            :placeholder="t('videos.time')"
            :items="chunkOptions"
            item-text="start_time"
            item-value="start_time"
            outlined
            clearable
            dense
            hide-details
            @change="updateFilter('views-order-video-list', 'time', chunkFilter)"
          ></v-select>
        </v-col>
      </v-row>

      <v-divider />

      <v-row
        class="pa-0 ma-0 text-center"
        justify="end"
      >
        <v-col
          v-if="hasPermission(2)"
          cols="12"
          sm="auto"
        >
          <v-btn
            color="secondary"
            block
            @click="onCreate"
          >
            {{ t('Order Video') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-divider />

      <v-row class="mx-0 mt-4">
        <v-col
          v-for="(video, i) in visibleVideoListTable"
          :key="i"
          md="3"
          sm="6"
          cols="12"
          class="align-self-start p-card"
        >
          <v-card
            :color="$vuetify.theme.dark ? '#1d1d1d' : '#fff'"
            class="rounded-lg"
            elevation="3"
            height="310"
          >
            <v-card-text class="pa-3">
              <div>
                <div
                  class="ms-0 width-full"
                >
                  <v-row class="pa-1">
                    <v-col
                      cols="12"
                      class="pa-1 d-flex justify-space-between align-center"
                    >
                      <div class="d-flex">
                        <v-avatar
                          color="primary"
                          :class="video.facility_logo ? '' : 'v-avatar-light-bg primary--text'"
                          size="32"
                        >
                          <v-img
                            v-if="video.facility_logo"
                            :src="video.facility_logo"
                          ></v-img>
                          <span
                            v-else
                            class="font-weight-medium"
                          >{{ avatarText(video.field_name) }}</span>
                        </v-avatar>
                        <div class="d-flex flex-column ml-2">
                          <span class="text-xs">{{ video.video_date_str }}</span>
                          <span class="text-xs">{{ video.field_name }}</span>
                        </div>
                      </div>
                      <span class="text-xs">{{ video.day_time }}</span>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-card-text>
            <v-carousel
              height="205px"
              width="auto"
              hide-delimiters
              show-arrows-on-hover
              cycle
              :show-arrows="false"
            >
              <v-carousel-item
                v-if="video.status === 'P' || video.status === 'S' || video.status === 'R'"
                no-use-v-if-with-v-for
                class="no-thum"
                eager
              >
                <v-progress-circular
                  v-if="video.status === 'P'"
                  indeterminate
                  color="secondary"
                  :size="20"
                  :width="2"
                  style="
                    position: absolute;
                    top: 37%;
                    right: 50%;
                    transform: translate(50%, 50%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                ></v-progress-circular>
                <span
                  v-if="video.status === 'S'"
                  name="lock"
                  style="
                    position: absolute;
                    top: 32%;
                    right: 50%;
                    transform: translate(50%, 50%);
                    background-color: #00000099;
                    border-radius: 100px;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <v-icon
                    color="secondary"
                    size="18"
                  >
                    {{ icons.mdiClockOutline }}
                  </v-icon>
                </span>
                <span
                  v-if="video.status === 'R'"
                  name="rec"
                  style="
                    position: absolute;
                    top: 32%;
                    right: 50%;
                    transform: translate(50%, 50%);
                    background-color: #00000099;
                    border-radius: 100px;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <v-icon
                    color="secondary"
                    size="18"
                  >
                    {{ icons.mdiRecordRec }}
                  </v-icon>
                </span>
              </v-carousel-item>
              <div v-else>
                <v-carousel-item
                  v-for="
                    (item,
                     index)
                      in
                        video.thumbnails"
                  :key="index"
                  :src="item"
                  eager
                >
                  <span
                    v-if="video.type === 'O'"
                    name="lock"
                    style="
                      position: absolute;
                      top: 32%;
                      right: 50%;
                      transform: translate(50%, 50%);
                      background-color: #00000099;
                      border-radius: 100px;
                      width: 40px;
                      height: 40px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <v-icon
                      color="white"
                      size="18"
                    >
                      {{ icons.mdiLock }}
                    </v-icon>
                  </span>
                  <v-chip
                    v-if="video.status === 'A' && video.type === 'V'"
                    class="absolute-chip text-xs"
                    color="#1d1d1dd4"
                    small
                  >
                    {{ video.video_time_str }}
                  </v-chip>
                </v-carousel-item>
              </div>
            </v-carousel>

            <v-card-actions class="dense mt-1 px-2">
              <v-sheet
                v-if="video.type === 'O'"
                max-width="100%"
                class="d-flex ma-0 mt-3"
                :color="$vuetify.theme.dark ? '#1d1d1d' : '#fff'"
              >
                <v-slide-group
                  v-if="hasPermission(2)"
                  center-active
                  show-arrows="desktop"
                >
                  <v-slide-item
                    v-for="n in minuteOptions"
                    :key="n.value"
                    v-slot="{ active }"
                  >
                    <v-fab-transition>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            color="success"
                            outlined
                            dark
                            x-small
                            :input-value="active"
                            active-class="purple white--text"
                            depressed
                            class="mt-xs-2 ma-0 mr-1"
                            style="min-width: 40px; padding: 0px 5px;"
                            v-on="on"
                            @click="comfirmAdd(video, n.value)"
                          >
                            {{ n.text }}
                          </v-btn>
                        </template>
                        <span>{{ t(`minutes.min_${n.value}`) }}</span>
                      </v-tooltip>
                    </v-fab-transition>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>
              <v-row
                v-else
                style="display: contents;"
              >
                <v-col
                  cols="12"
                  class="d-flex justify-space-between px-0 align-center"
                >
                  <span
                    v-if="video.status === 'P' || video.status === 'S' || video.status === 'R'"
                    class="text-xs"
                  >
                    <span v-if="video.remaining_time > 0 && video.remaining_time < 3600000">
                      <countdown
                        :time="video.remaining_time"
                        :transform="transform"
                        @end="onEndTime(video)"
                      >
                        <template slot-scope="props">{{ props.minutes }}:{{ props.seconds }} {{ t('videos.to_edit') }}</template>
                      </countdown>
                    </span>
                    <span v-else> {{ video.status_str }}</span>
                  </span>
                  <span
                    v-if="video.status === 'A'"
                    class="text-xs"
                  >
                    {{ video.highlights_count }} {{ t('videos.highlights') }}
                  </span>

                  <div>
                    <v-btn
                      v-if="video.can_cancel && hasPermission(64)"
                      class="ma-0 mr-2"
                      x-small
                      depressed
                      style="min-width: 40px; padding: 0px 5px; font-size:10px;"
                      outlined
                      color="secondary"
                      @click="cancelOrder(video)"
                    >
                      {{ t('tooltip.cancel') }}
                    </v-btn>
                    <v-btn
                      v-if="video.can_edit && hasPermission(70)"
                      class="ma-0 mr-2"
                      x-small
                      depressed
                      style="min-width: 40px; padding: 0px 5px; font-size:10px;"
                      outlined
                      color="secondary"
                      @click="comfirmAdd(video, video.video_time, null, null, true)"
                    >
                      {{ t('tooltip.edit') }}
                    </v-btn>
                    <v-btn
                      v-if="video.status === 'A' && hasPermission(65)"
                      class="ma-0"
                      x-small
                      depressed
                      style="min-width: 40px; padding: 0px 5px; font-size:10px;"
                      outlined
                      color="success"
                      @click="showQR(video)"
                    >
                      {{ t('videos.show_qr') }}
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          class="text-center align-self-center pa-4"
        >
          <v-pagination
            v-if="lengthPagination"
            v-model="page"
            :length="lengthPagination"
            :total-visible="7"
            color="secondary"
          ></v-pagination>
          <p
            v-else
            class="mt-3"
          >
            {{ t('tooltip.no_data') }}
          </p>
        </v-col>
      </v-row>
    </v-card>

    <order-video-new
      v-model="isOrderVideoNewOpen"
      :facilities-options="facilitiesOptions"
      :groups-options="groupsOptions"
      :field-options="fieldOptions"
      :group-filter="configOrganization"
      :facility-filter="configFacility"
      :field-filter="fieldFilter"
      :date-filter="dateFilter"
      :chunk-filter="chunkFilter"
      :time-selected="timeSelected"
      :img-preview="visibleVideoListTable"
      :max-date="maxDate"
      :min-date="minDate"
      @openCloseNew="openCloseNew"
      @changeFacilitiesOptions="changeFacilitiesOptions"
      @changeGroupsOptions="changeGroupsOptions"
    ></order-video-new>

    <v-dialog
      v-model="isDialogVisible"
      max-width="400px"
    >
      <v-card rounded="10">
        <v-card-text class="pa-0">
          <v-carousel
            v-if="videoPreOrder && videoPreOrder.thumbnails.length"
            height="220"
            width="auto"
            hide-delimiters
            show-arrows-on-hover
            cycle
            :show-arrows="false"
          >
            <v-carousel-item
              v-for="
                (item,
                 index)
                  in
                    videoPreOrder.thumbnails"
              :key="index"
              :src="item"
            >
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
        <v-card-text class="py-3 px-1">
          <v-list
            v-if="details.length"
            subheader
            dense
          >
            <v-list-item
              v-for="detail in details"
              :key="detail.title"
              dense
              style="min-height: 30px;"
            >
              <v-list-item-content
                v-if="!(detail.title === 'group' && detail.class === 'edit')"
                class="py-0"
              >
                <v-list-item-title
                  :class="detail.class ? detail.class : ''"
                  v-text="t(`video_details.${detail.title}`)"
                ></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action
                v-if="!(detail.title === 'group' && detail.class === 'edit')"
                class="ma-0 mb-1"
              >
                <v-list-item-title
                  v-if="detail.title === 'group'"
                >
                  <v-select
                    v-model="detail.subtitle"
                    :placeholder="t('organizations.organization')"
                    :items="groupFacilityOptions"
                    item-text="name"
                    item-value="id"
                    class="detail-select"
                    style="width: 180px;"
                    outlined
                    dense
                    readonly
                    :append-icon="null"
                    :disabled="userData.role !== 'A'"
                    hide-details
                    @change="comfirmAdd(videoPreOrder, details[1].subtitle, detail.subtitle, details[2].subtitle)"
                  >
                  </v-select>
                </v-list-item-title>
                <v-list-item-title
                  v-else-if="detail.title === 'duration'"
                >
                  <v-select
                    v-model="detail.subtitle"
                    :placeholder="t('videos.minutes')"
                    :items="minuteOptions"
                    item-text="text"
                    item-value="value"
                    class="detail-select"
                    style="width: 180px;"
                    outlined
                    dense
                    hide-details
                    @change="comfirmAdd(videoPreOrder, detail.subtitle, null, isEdit ? null : details[2].subtitle, isEdit)"
                  >
                  </v-select>
                </v-list-item-title>
                <v-list-item-title
                  v-else-if="detail.title === 'video_type'"
                >
                  <v-select
                    v-model="detail.subtitle"
                    :placeholder="t('videos.video_type')"
                    :items="videoTypesOptions"
                    item-text="title"
                    item-value="value"
                    class="detail-select"
                    style="width: 180px;"
                    outlined
                    dense
                    hide-details
                    @change="comfirmAdd(videoPreOrder, details[1].subtitle, null, detail.subtitle, isEdit)"
                  >
                  </v-select>
                </v-list-item-title>
                <v-list-item-title
                  v-else-if="detail.title === 'time' || detail.title === 'start_time'"
                >
                  <v-autocomplete
                    v-if="detail.class === 'edit'"
                    v-model="startTime"
                    :placeholder="t('videos.start_time')"
                    :items="startTimeOptions"
                    item-text="text"
                    item-value="value"
                    class="detail-select"
                    style="width: 180px;"
                    outlined
                    dense
                    hide-details
                    @change="changeStartTime"
                  >
                  </v-autocomplete>
                  <p
                    v-else
                    class="ma-0"
                  >
                    {{ detail.subtitle }}
                  </p>
                </v-list-item-title>
                <v-list-item-title
                  v-else
                  :class="detail.class ? detail.class : ''"
                  v-text="detail.subtitle"
                ></v-list-item-title>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <p
            class="ma-0 text-xs text-center"
            style="font-size: 0.7rem !important;"
          >
            {{ t('videos.video_cost') }}
          </p>
        </v-card-text>
        <v-card-actions class="justify-center pb-3">
          <v-btn
            class="text-xs"
            color="secondary"
            dark
            width="40%"
            rounded
            @click="onNotify"
          >
            {{ isEdit ? t('tooltip.edit_order') : t('tooltip.continue') }}
          </v-btn>
          <v-btn
            class="text-xs"
            color="lightGray"
            dark
            width="30%"
            rounded
            outlined
            @click="onCancelDialog"
          >
            {{ t('tooltip.close') }}
          </v-btn>
          <v-progress-circular
            v-if="timeInterval"
            class="ml-2"
            :rotate="-90"
            :width="3"
            color="red"
            size="40"
            style="font-size: 0.6rem !important;"
            :value="valueInterval"
          >
            {{ computedTimeInterval }}
          </v-progress-circular>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isDialogQRVisible"
      max-width="280px"
    >
      <v-card
        class="pa-3"
        rounded="10"
      >
        <v-card-text class="pa-0 my-4 justify-center d-flex">
          <v-row class="justify-center align-center">
            <v-col
              md="12"
              cols="12"
              class="d-flex justify-center align-center"
            >
              <qr
                :value="videoQR"
                :show="true"
                :level="'H'"
                :size="450"
                fg-color="#ff3232"
              />
            </v-col>

            <v-col
              md="12"
              cols="12"
              class="justify-center align-center"
            >
              <v-text-field
                v-model="videoQR"
                :label="t('sports.name')"
                dense
                outlined
                readonly
                hide-details
                :append-outer-icon="copyDynamicIcon === 'copy' ? icons.mdiContentCopy : icons.mdiCheck"
                @click:append-outer="copyActiveCode"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <notify-players
      v-model="isDialogPhoneVisible"
      :required-organizer-phone="isRequiredPhone"
      @openClose="openClose"
      @continue="onContinue"
    ></notify-players>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
import { mdiPlus, mdiRefresh, mdiLock, mdiClockOutline, mdiRecordRec, mdiContentCopy, mdiCheck } from '@mdi/js'
import { computed, onMounted, ref } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import {
  getVideoPreorder,
  createOrderVideo,
  createRecurringOrderVideo,
  editOrderVideo,
  createPayment,
  cancelVideoTransaction,
  getGroupByFacility,
  getUserPing,
  getOrderVideoStartTimes,
} from '@api'
import { formatCurrency, isDev } from '@core/utils'
import { info, error, success } from '@core/utils/toasted'
import usePermissions from '@core/utils/usePermissions'
import { useClipboard } from '@vueuse/core'
import useAppConfig from '@core/@app-config/useAppConfig'
import Swal from 'sweetalert2'
import { DURATION_OPTIONS } from '@core/utils/reservation'
import useFilters from '@core/utils/useFilters'

// sidebar
import moment from 'moment'
import Qr from '@/views/components/qr/Qr.vue'
import useOrderVideoList from './useOrderVideoList'
import OrderVideoNew from '../order-video-preview/OrderVideoNew.vue'
import NotifyPlayers from '../NotifyPlayers.vue'

export default {
  components: {
    OrderVideoNew,
    Qr,
    NotifyPlayers,
  },
  setup() {
    const { t } = useUtils()
    const { hasPermission } = usePermissions()
    const { copy, isSupported } = useClipboard()
    const { isDark } = useAppConfig()
    const { updateFilter, getFilterByModule } = useFilters()
    const {
      userData,
      searchQuery,
      fieldFilter,
      chunkFilter,
      dateFilter,
      maxDate,
      minDate,
      groupsOptions,
      facilitiesOptions,
      fieldOptions,
      chunkOptions,
      videoListTable,
      totalVideoListTable,
      lengthPagination,
      visibleVideoListTable,
      page,
      perPage,
      configOrganization,
      configFacility,

      fetchVideos,
      fetchFields,
      fetchChunks,
    } = useOrderVideoList()

    const isDialogVisible = ref(false)
    const isDialogQRVisible = ref(false)
    const isDialogPhoneVisible = ref(false)
    const isRequiredPhone = ref(false)
    const videoQR = ref(null)
    const modal = ref(null)
    const isEdit = ref(false)
    const startTime = ref(null)
    const isOrderVideoNewOpen = ref(false)
    const timeSelected = ref(null)
    const imgPreview = ref(null)
    const timeInterval = ref(0)
    const valueInterval = ref(0)
    const videoComponentKey = ref(0)
    const computedTimeInterval = computed(() => moment.utc(timeInterval.value * 1000).format('mm:ss'))

    const dataNotify = ref({
      phone: null,
      phones: [],
    })
    const videoPreOrder = ref(null)
    const details = ref([])
    const startTimeOptions = ref([])
    const minuteOptions = ref(DURATION_OPTIONS)
    const videoTypesOptions = computed(() => [
      { title: t('status.premium'), value: 'T' },
      { title: t('status.basic'), value: 'S' },
    ])
    const computedMinutes = computed(() => {})
    const groupFacilityOptions = ref([])
    const copyDynamicIcon = ref('copy')

    const forceRerender = () => {
      videoComponentKey.value += 1
    }

    const onCreate = async () => {
      if (isOrderVideoNewOpen.value) {
        isOrderVideoNewOpen.value = false
      } else {
        await getUserPing()
        isOrderVideoNewOpen.value = true
      }
    }

    const onAdd = video => {
      if (video.video_time) {
        if (!fieldFilter.value) fieldFilter.value = video.field_id
        timeSelected.value = video.video_time
        const chunkFinded = chunkOptions.value.find(e => e.start_time === video.start_time)

        if (chunkFinded) chunkFilter.value = chunkFinded.start_time
        if (isOrderVideoNewOpen.value) {
          isOrderVideoNewOpen.value = false
        } else {
          isOrderVideoNewOpen.value = true
        }
      } else {
        info('Seleccione minutos')
      }
    }

    const openCloseNew = value => {
      isOrderVideoNewOpen.value = value
      fetchVideos()
    }

    const openClosePhone = value => {
      isDialogPhoneVisible.value = value
      fetchVideos()
    }

    const onCancelDialog = auxInterval => {
      isDialogVisible.value = false
      if (auxInterval) clearInterval(auxInterval)
      timeInterval.value = 0
      valueInterval.value = 0
      startTime.value = null
      details.value = []
    }

    const getClientFinalAmount = video => video.subtotal + video.tax // - video.comission // - video.discount

    const getGroupId = group => {
      if (group) return group
      if (userData.value && userData.value.role !== 'A') return userData.value.group_id
      if (userData.value && userData.value.role === 'A' && configOrganization.value !== null) return configOrganization.value

      return 0
    }

    const comfirmAdd = async (video, videoTime, group, videoType, edit) => {
      isEdit.value = edit
      isDialogVisible.value = false
      details.value = []
      const gr = getGroupId(group)

      let videoTypeOrder = 'U'
      if (videoType) {
        videoTypeOrder = videoType
      }

      if (edit) {
        const startTimes = await getOrderVideoStartTimes(video.video_id)
        startTimeOptions.value = startTimes.data.map(e => {
          return {
            text: e,
            value: e,
          }
        })
      }
      const response = await getVideoPreorder(gr, video.field_id, video.video_date, video.start_time, videoTime, videoTypeOrder)
      if (response.ok) {
        videoPreOrder.value = response.data
        isRequiredPhone.value = videoPreOrder.value.order_phone_number === 'Y'
        if (!videoPreOrder.value.video_id) videoPreOrder.value.video_id = video.video_id
        if (!videoPreOrder.value.facility_id) videoPreOrder.value.facility_id = video.facility_id
        const responseGroups = await getGroupByFacility(video.facility_id)
        groupFacilityOptions.value = responseGroups.data
        details.value.push({
          subtitle: videoPreOrder.value.group_id,
          title: 'group',
          class: edit ? 'edit' : '',
        })

        if (edit) {
          if (startTime.value) videoPreOrder.value.start_time = startTime.value
          else startTime.value = videoPreOrder.value.start_time
          details.value.push({
            subtitle: edit ? videoPreOrder.value.start_time : videoPreOrder.value.video_schedule,
            title: edit ? 'start_time' : 'time',
            class: edit ? 'edit' : '',
          })

          details.value.push({
            subtitle: videoPreOrder.value.video_time,
            title: 'duration',
            class: '',
          })
        } else {
          details.value.push({
            subtitle: videoPreOrder.value.video_time,
            title: 'duration',
            class: '',
          })

          if (videoPreOrder.value.can_change_order_type) {
            details.value.push({
              subtitle: videoPreOrder.value.video_type,
              title: 'video_type',
              class: '',
            })
          }

          details.value.push({
            subtitle: videoPreOrder.value.video_schedule,
            title: 'time',
            class: '',
          })
        }

        details.value.push({
          subtitle: videoPreOrder.value.video_date_str,
          title: 'date',
        })

        details.value.push({
          subtitle: videoPreOrder.value.facility_name,
          title: 'facility',
        })

        details.value.push({
          subtitle: videoPreOrder.value.field_name,
          title: 'field',
        })

        details.value.push({
          subtitle: videoPreOrder.value.sport_name,
          title: 'sport',
        })

        details.value.push({
          subtitle: formatCurrency(videoPreOrder.value.subtotal),
          title: 'amount',
          class: '',
        })

        // if (videoPreOrder.value.discount) {
        //   details.value.push({
        //     subtitle: `(${formatCurrency(videoPreOrder.value.discount)})`,
        //     title: 'discount',
        //     class: 'success--text',
        //   })
        // }

        details.value.push({
          subtitle: `${formatCurrency(videoPreOrder.value.tax)}`,
          title: 'tax',
          class: '',
        })

        // if (videoPreOrder.value.comission) {
        //   details.value.push({
        //     subtitle: `(${formatCurrency(videoPreOrder.value.comission)})`,
        //     title: 'comission',
        //     class: 'success--text',
        //   })
        // }

        // subtitle: formatCurrency(videoPreOrder.value.amount),
        details.value.push({
          subtitle: formatCurrency(getClientFinalAmount(videoPreOrder.value)),
          title: 'final_amount',
          class: 'font-weight-bold text-md',
        })

        isDialogVisible.value = true
        if (videoPreOrder.value.expiration_time) {
          timeInterval.value = videoPreOrder.value.expiration_time
          const acc = 100 / videoPreOrder.value.expiration_time

          const auxInterval = setInterval(() => {
            if (timeInterval.value > 0) {
              timeInterval.value -= 1
              valueInterval.value += acc
            } else {
              onCancelDialog(auxInterval)
              comfirmAdd(video, videoTime, group, videoTypeOrder, edit)

              // fetchVideos()
            }
          }, 1000)
        }
      } else {
        error(response.message.text)
      }
    }

    const onAddPhoneNumbers = () => {
      isDialogPhoneVisible.value = true
    }

    const onNotify = () => {
      isDialogVisible.value = false
      isDialogPhoneVisible.value = true
    }

    const onCreateOrder = async () => {
      // chunk_id: videoPreOrder.value.chunk_id,
      // discount: videoPreOrder.value.discount,
      // organizer_phone_number: videoPreOrder.value.organizer_phone_number,

      const order = {
        amount: videoPreOrder.value.amount,
        subtotal: videoPreOrder.value.subtotal,
        comission: videoPreOrder.value.comission,
        tax: videoPreOrder.value.tax,
        start_time: videoPreOrder.value.start_time,
        video_time: videoPreOrder.value.video_time,
        video_type: videoPreOrder.value.video_type,
        group_id: videoPreOrder.value.group_id,

        field_id: videoPreOrder.value.field_id,
        username: userData.value.username,
        video_date: videoPreOrder.value.video_date,

        make_recurring: videoPreOrder.value.make_recurring,
        weekdays: videoPreOrder.value.weekdays,
        phone_numbers: videoPreOrder.value.phone_numbers,
      }

      let resp = null
      if (isEdit.value) {
        resp = await editOrderVideo(order, videoPreOrder.value.video_id)
      } else {
        resp = await createOrderVideo(order)
        if (resp.ok && videoPreOrder.value.make_recurring === 'Y') {
          const recurringBody = {
            recurring_order: {
              group_id: videoPreOrder.value.group_id,
              facility_id: videoPreOrder.value.facility_id,
              field_id: videoPreOrder.value.field_id,
              user_id: userData.value.username,
              start_time: videoPreOrder.value.start_time,
              video_time: videoPreOrder.value.video_time,
              weekdays: videoPreOrder.value.weekdays,
              phone_numbers: videoPreOrder.value.phone_numbers,
            },
            video_date: videoPreOrder.value.video_date,
          }
          await createRecurringOrderVideo(recurringBody)
        }
      }

      let message = null
      if (resp.ok) {
        message = resp.message
        success(message)

        if (videoPreOrder.value.make_payment) {
          const dataPayment = {
            amount: videoPreOrder.value.amount,
            payment_method_id: videoPreOrder.value.payment_method_id,
            note: null,
            transactions: [resp.data.transaction_id],
            username: userData.value.username,
          }
          await createPayment(dataPayment)
        }
        isDialogVisible.value = false
        isDialogPhoneVisible.value = false
        isRequiredPhone.value = false
        startTime.value = null
        details.value = []
        await fetchVideos()
      } else {
        message = resp.message.text
        error(message)
      }
    }

    // videoPreOrder.value.organizer_phone_number = data.organizer_phone_number
    const onContinue = data => {
      if (data) {
        videoPreOrder.value.make_recurring = data.make_recurring ? 'Y' : 'N'
        videoPreOrder.value.weekdays = data.weekdays
        videoPreOrder.value.phone_numbers = data.phone_numbers
        onCreateOrder()
      }
    }

    const openClose = val => {
      isDialogPhoneVisible.value = val
    }

    const showQR = video => {
      isDialogQRVisible.value = true
      videoQR.value = video.share_url
    }

    const cancelOrder = async video => {
      Swal.fire({
        title: 'Cancel Order?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Cancel Order!',
        cancelButtonText: 'No',
      }).then(async result => {
        if (result.isConfirmed) {
          const resp = await cancelVideoTransaction(video.video_id, {
            refund: 'Y',
            hide_video: 'Y',
            username: userData.value.username,
            cancel_type: 'V',
          })
          if (resp.ok) {
            await fetchVideos()
            success(resp.message)
          } else error(resp.message.text)
        }
      })
    }

    const changeMinutes = value => {
      videoPreOrder.value.video_time = value
    }

    const changeFacilitiesOptions = id => {
      // facilityFilter.value = id
      console.log(id)
    }

    const changeGroupsOptions = id => {
      // groupFilter.value = id
      console.log(id)
    }

    const changeStartTime = event => {
      videoPreOrder.value.start_time = event
    }

    onMounted(async () => {
      await fetchFields(configFacility.value)

      if (isDev.value && minuteOptions.value.findIndex(e => e.value === 5) === -1) minuteOptions.value.unshift({ text: '5 Min', value: 5 })

      const filters = getFilterByModule('views-order-video-list')
      if (filters.date) dateFilter.value = filters.date
      if (filters.field) {
        fieldFilter.value = filters.field
        await fetchChunks(fieldFilter.value, dateFilter.value)
      }
      if (filters.time) chunkFilter.value = filters.time

      await fetchVideos()
    })

    const copyActiveCode = () => {
      if (isSupported) {
        copy(videoQR.value).then(() => {
          copyDynamicIcon.value = 'copied'
          setTimeout(() => {
            copyDynamicIcon.value = 'copy'
          }, 5000)
        })
      }
    }

    const onEndTime = video => {
      video.remaining_time = 0
      video.can_edit = false
      video.can_cancel = false
    }

    const transform = timeProps => {
      Object.entries(timeProps).forEach(([key, value]) => {
        // Adds leading zero
        const digits = value < 10 ? `0${value}` : value

        timeProps[key] = `${digits}`
      })

      return timeProps
    }

    return {
      userData,
      searchQuery,
      fieldFilter,
      chunkFilter,
      dateFilter,
      maxDate,
      minDate,
      videoTypesOptions,
      groupsOptions,
      facilitiesOptions,
      fieldOptions,
      chunkOptions,
      imgPreview,
      isDialogVisible,
      isDialogQRVisible,
      isDialogPhoneVisible,
      isRequiredPhone,
      videoComponentKey,
      videoQR,
      details,
      dataNotify,
      videoPreOrder,
      timeInterval,
      valueInterval,
      computedTimeInterval,
      computedMinutes,
      groupFacilityOptions,
      copyDynamicIcon,
      isEdit,
      startTime,
      configOrganization,
      configFacility,
      isDark,

      onEndTime,
      transform,
      onCreate,
      fetchVideos,
      fetchFields,
      fetchChunks,
      forceRerender,
      page,
      perPage,
      modal,
      timeSelected,
      minuteOptions,
      startTimeOptions,
      lengthPagination,
      videoListTable,
      totalVideoListTable,
      visibleVideoListTable,
      isOrderVideoNewOpen,
      openCloseNew,
      openClosePhone,
      onAdd,
      comfirmAdd,
      onAddPhoneNumbers,
      onCreateOrder,
      formatCurrency,
      changeMinutes,
      onCancelDialog,
      changeFacilitiesOptions,
      changeGroupsOptions,
      showQR,
      cancelOrder,
      getClientFinalAmount,
      hasPermission,
      copyActiveCode,
      changeStartTime,
      onContinue,
      onNotify,
      openClose,
      updateFilter,

      // i18n
      t,

      // icons
      icons: {
        mdiPlus,
        mdiRefresh,
        mdiLock,
        mdiClockOutline,
        mdiRecordRec,
        mdiContentCopy,
        mdiCheck,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/apps/user.scss';

.no-thum {
  background-color: #979797;
  background-image: none;
}

div.v-image__image {
  background-size: 100% 100%;
}

div.v-slide-group__prev,
div.v-slide-group__next {
  min-width: 30px;

  svg.v-icon__svg {
    height: 20px;
    width: 20px;
    fill: currentColor;
  }
}

.v-input.detail-select.v-input__control.v-input__slot {
  min-height: 30px;
  height: 30px;
}

.detail-select.v-input__control.v-input__slot,
.detail-select.v-list-item__action .v-input__control {
  min-height: 30px;
  height: 30px;
}

.v-window__prev,
.v-window__next {
  button {
    font-size: 20px !important;
  }

  v-icon {
    font-size: 20px;
  }

  .lock-icon {
    position: absolute !important;
    top: 35% !important;
    right: 50% !important;
    transform: translate(50%, 50%) !important;
  }

  div .v-input__append-outer {
    margin: 0;
  }

  .width-full {
    width: -webkit-fill-available;
    width: fit-content;
  }
}

.absolute-chip {
  bottom: 30px;
  right: 10px;
  position: absolute;
}

.p-card {
  padding: 10px !important;
  padding-top: 0 !important;
}

.m-card {
  margin-inline: 10px !important;
}

.mt-filter-row {
  margin-top: 0px !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .p-card {
    padding: 12px !important;
    padding-top: 0 !important;
  }

  .m-card {
    margin-inline: 12px !important;
  }

  .absolute-chip {
    right: 10px;
    bottom: 10px;
  }

  .mt-filter-row {
    margin-top: 24px !important;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .p-card {
    padding: 12px !important;
    padding-top: 0 !important;
  }

  .m-card {
    margin-inline: 12px !important;
  }

  .absolute-chip {
    right: 10px;
    bottom: 10px;
  }

  .mt-filter-row {
    margin-top: 24px !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .p-card {
    padding: 12px !important;
    padding-top: 0 !important;
  }

  .m-card {
    margin-inline: 12px !important;
  }

  .absolute-chip {
    right: 10px;
    bottom: 10px;
  }

  .mt-filter-row {
    margin-top: 0px !important;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .p-card {
    padding: 10px !important;
    padding-top: 0 !important;
    bottom: 30px;
  }

  .m-card {
    margin-inline: 10px !important;
  }

  .absolute-chip {
    right: 10px;
    bottom: 10px;
  }

  .mt-filter-row {
    margin-top: 0px !important;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .p-card {
    padding: 10px !important;
    padding-top: 0 !important;
  }

  .m-card {
    margin-inline: 10px !important;
  }

  .absolute-chip {
    right: 10px;
    bottom: 10px;
    z-index: 15;
  }

  .mt-filter-row {
    margin-top: 0px !important;
  }
}
</style>
